import React, { useContext, useState } from 'react'
import { View, Text, ScrollView } from 'react-native'

import { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import { getCalendlyLink } from './utils'
import { ChevronRightIcon } from 'src/icons'
import { ErrorAlert, errorTypes, StatusInfo } from 'src/scenes/Main/Profile/RequiredTasks/components'
import { isSESelector } from 'src/store/app/selectors'
import { markTaskAsCompleted } from '../actions'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { openURLIfCan } from 'src/global/utils'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { profileTaskStatus } from 'src/scenes/Main/Profile/types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { useBugsnagView } from 'src/utils/bugsnag'
import BookInterviewConfirmation from './BookInterviewConfirmation/BookInterviewConfirmation'
import ButtonNew from 'src/components/Buttons/ButtonNew/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox'
import translations, { translate } from 'src/utils/translations/translations'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'BookInterview'>

const BookInterview = ({ route, navigation }: Props) => {
  const { status, timeToComplete, task } = route.params
  const isCompleted = status === profileTaskStatus.completed

  useBugsnagView('RequiredTask -  BookInterview')

  const userId = useAppSelector(state => state.user.id) as string
  const userRegion = useAppSelector(state => state.user.region?.attributes?.name)
  const isSE = useAppSelector(isSESelector)

  const [isBooked, setIsBooked] = useState(isCompleted)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isError, setIsError] = useState(false)

  const { mixpanel } = useContext(MixpanelContext)

  const openConfirmationModal = () => {
    !isBooked && setIsConfirmationModalOpen(true)
    setIsBooked(prev => !prev)
  }

  const closeModal = () => {
    setIsBooked(false)
    setIsConfirmationModalOpen(false)
  }

  const openCalendly = () => {
    const link = getCalendlyLink(userRegion)
    openURLIfCan(link)
  }

  const completeTask = () => {
    setIsError(false)
    markTaskAsCompleted(userId, task, false, mixpanel, navigation.goBack, () => {
      closeModal()
      setIsError(true)
    })
  }

  if (isCompleted)
    return (
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={globalStyles.containerSize}
        testID="scrollView"
      >
        <View style={styles.container}>
          <StatusInfo status={status} timeToComplete={timeToComplete} />
          <View style={styles.checkboxDisabled}>
            <Checkbox
              disabled
              label={translate(translations.bookInterviewConfirmationCheckbox)}
              onPress={openConfirmationModal}
              selected
            />
          </View>
          <Text style={styles.header}>{translate(translations.bookInterviewCompletedTitle)}</Text>
          <Text style={styles.mainInfo}>{translate(translations.bookInterviewCompletedInfo1)}</Text>
          {isSE ? (
            <>
              <Text style={styles.subtitle}>{translate(translations.bookInterviewCompletedSubTitle)}</Text>
              <Text style={styles.mainInfo}>{translate(translations.bookInterviewCompletedInfo2)}</Text>
              <Text style={styles.subtitle}>{translate(translations.bookInterviewCompletedSubTitle2)}</Text>
              <Text style={styles.mainInfo}>{translate(translations.bookInterviewCompletedInfo3)}</Text>
            </>
          ) : null}
        </View>
      </ScrollView>
    )

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={globalStyles.containerSize}
        testID="scrollView"
      >
        <View style={styles.container}>
          <StatusInfo status={status} timeToComplete={timeToComplete} />
          <Text style={styles.header}>{translate(translations.bookInterviewTitle)}</Text>
          <Text style={styles.mainInfo}>{translate(translations.bookInterviewDescription)}</Text>
          <Text style={styles.subInfo}>{translate(translations.bookInterviewInfo)}</Text>
          <View style={styles.sectionContainer}>
            {isError ? <ErrorAlert hideError={() => setIsError(false)} type={errorTypes.confirmation} /> : null}
            <View style={styles.buttonContainer}>
              <ButtonNew
                onPress={openCalendly}
                RightIcon={ChevronRightIcon}
                size={buttonSize.lg}
                title={translate(translations.bookInterview)}
                variant={buttonVariants.containedDefault}
              />
            </View>
          </View>
          <View style={styles.checkboxContainer}>
            <Checkbox
              label={translate(translations.bookInterviewConfirmationCheckbox)}
              onPress={openConfirmationModal}
              selected={isBooked}
            />
          </View>
          <Text style={styles.subInfo}>{translate(translations.bookInterviewConfirmationInfo)}</Text>
          {isSE ? (
            <View style={styles.footer}>
              <Text style={styles.taskInfo}>{translate(translations.bookInterviewTaskInfo)}</Text>
            </View>
          ) : null}
        </View>
      </ScrollView>
      <BookInterviewConfirmation onCancel={closeModal} onConfirm={completeTask} isModalOpen={isConfirmationModalOpen} />
    </>
  )
}

export default BookInterview
