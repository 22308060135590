import * as Yup from 'yup'

import formatter from 'src/utils/formatter'
import translations, { translate } from 'src/utils/translations/translations'

const getValidationSchema = () =>
  Yup.object().shape({
    endDate: Yup.string()
      .when('isOngoing', {
        is: isOngoing => isOngoing === false,
        then: schema => schema.required(translate(translations.blank)),
      })
      .test({
        name: 'startDate vs endDate',
        test: function test() {
          if (this.parent.isOngoing) {
            return true
          }
          return this.parent.startDate && this.parent.endDate && !this.parent.isOngoing
            ? formatter.getDatesDifferenceInDays(this.parent.endDate, this.parent.startDate) >= 0
            : true
        },
        message: () => translate(translations.errorDateAvailability),
      }),
    name: Yup.string().required(translate(translations.blank)),
    qualificationType: Yup.string().required(translate(translations.blank)),
    startDate: Yup.string()
      .required(translate(translations.blank))
      .test({
        name: 'startDate vs endDate',
        test: function test() {
          if (this.parent.isOngoing) {
            return true
          }
          return this.parent.startDate && this.parent.endDate && !this.parent.isOngoing
            ? formatter.getDatesDifferenceInDays(this.parent.endDate, this.parent.startDate) >= 0
            : true
        },
        message: () => translate(translations.errorDateAvailability),
      }),
    subject: Yup.string().required(translate(translations.blank)),
  })

export default getValidationSchema
