import * as Yup from 'yup'

import translations, { translate } from 'src/utils/translations/translations'

const getValidationSchema = () =>
  Yup.object().shape({
    hasHealthIssues: Yup.boolean().required(translate(translations.blank)),
    mentallyAndphysicallyFit: Yup.boolean().required(translate(translations.emergencyRelationError)),
    comment: Yup.string().when('hasHealthIssues', {
      is: hasHealthIssues => hasHealthIssues === true,
      then: schema => schema.required(translate(translations.blank)),
    }),
  })

export default getValidationSchema
