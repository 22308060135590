import * as Yup from 'yup'

import translations, { translate } from 'src/utils/translations/translations'
import formatter from 'src/utils/formatter'

export const validationSchema = Yup.object().shape({
  arrivalTime: Yup.string().test({
    name: 'arrivalTime',
    test: function test() {
      return formatter.getDifferenceInMinutes(this.parent.arrivalTime, this.parent.departureTime) > 0
    },
    message: () => translate(translations.reportTimeStartError),
  }),
  departureTime: Yup.string().test({
    name: 'departureTime',
    test: function test() {
      return formatter.getDifferenceInMinutes(this.parent.arrivalTime, this.parent.departureTime) > 0
    },
    message: () => translate(translations.reportTimeEndError),
  }),
  overtimeReason: Yup.mixed().when(['$isOvertime'], {
    is: isOvertime => !!isOvertime,
    then: () => Yup.string().ensure().required(translate(translations.blank)),
    otherwise: schema => schema.nullable(),
  }),
  overtimeRequestedBy: Yup.mixed().when(['$isOvertime'], {
    is: isOvertime => !!isOvertime,
    then: () => Yup.string().ensure().required(translate(translations.blank)),
    otherwise: schema => schema.nullable(),
  }),
})
