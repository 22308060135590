export function getCalendlyLink(region: string | null | undefined) {
  switch (region) {
    case 'Dalarna':
      return 'https://calendly.com/humly/intervju-dalarna'
    case 'Göteborg':
      return 'https://bit.ly/3wxdWL9'
    case 'Katrineholm':
      return 'https://calendly.com/humly/intervju-kholm'
    case 'Skåne':
      return 'https://bit.ly/3QIGhVX'
    case 'Stockholm':
      return 'https://bit.ly/4dAC0xw'
    case 'Umeå':
      return 'https://bit.ly/3UXcwmz'
    case 'Birmingham':
    case 'Black Country':
    case 'Bristol':
    case 'Liverpool':
    case 'Manchester':
    case 'North West - Midlands':
    case 'Nottingham':
    case 'Other Midlands':
    case 'Sheffield':
    case 'South West':
    case 'Warwickshire':
    case 'Milton Keynes':
      return 'https://bit.ly/3wxbrsg'
    case 'Other North East':
    case 'Tees Valley':
    case 'Tyne and Wear':
      return 'https://bit.ly/4dA05o9'
    case 'Blackburn':
    case 'Blackpool':
    case 'Carlisle':
    case 'Other North West':
    case 'Preston':
      return 'https://bit.ly/4euxQaO'
    case 'Other South':
    case 'Portsmouth':
    case 'Southampton':
      return 'https://bit.ly/3R5rLrx'
    default:
      return 'https://calendly.com/humly'
  }
}
