import * as Yup from 'yup'

import translations, { translate } from 'src/utils/translations/translations'

export const validationSchema = Yup.object().shape({
  reportedTimeScheduleType: Yup.string().oneOf(['pm', 'am', 'full_day']),
  overtimeReason: Yup.mixed().when(['$bookingScheduleType', 'reportedTimeScheduleType'], {
    is: (bookingScheduleType, reportedTimeScheduleType) => bookingScheduleType !== reportedTimeScheduleType,
    then: () => Yup.string().ensure().required(translate(translations.blank)),
    otherwise: schema => schema.nullable(),
  }),
  overtimeRequestedBy: Yup.mixed().when(['$bookingScheduleType', 'reportedTimeScheduleType'], {
    is: (bookingScheduleType, reportedTimeScheduleType) => bookingScheduleType !== reportedTimeScheduleType,
    then: () => Yup.string().ensure().required(translate(translations.blank)),
    otherwise: schema => schema.nullable(),
  }),
})
