import * as Yup from 'yup'

import translations, { translate } from 'src/utils/translations/translations'
import formatter from 'src/utils/formatter'

const common = {
  employer: Yup.string().required(translate(translations.blank)),
  endDate: Yup.string()
    .when('isOngoing', {
      is: isOngoing => isOngoing === false,
      then: schema => schema.required(translate(translations.blank)),
    })
    .test({
      name: 'startDate vs endDate',
      test: function test() {
        if (this.parent.isOngoing) {
          return true
        }
        return this.parent.startDate && this.parent.endDate && !this.parent.isOngoing
          ? formatter.getDatesDifferenceInDays(this.parent.endDate, this.parent.startDate) >= 0
          : true
      },
      message: () => translate(translations.errorDateAvailability),
    }),
  role: Yup.string().required(translate(translations.blank)),
  startDate: Yup.string()
    .required(translate(translations.blank))
    .test({
      name: 'startDate vs endDate',
      test: function test() {
        if (this.parent.isOngoing) {
          return true
        }
        return this.parent.startDate && this.parent.endDate && !this.parent.isOngoing
          ? formatter.getDatesDifferenceInDays(this.parent.endDate, this.parent.startDate) >= 0
          : true
      },
      message: () => translate(translations.errorDateAvailability),
    }),
  workload: Yup.string().required(translate(translations.blank)),
}

const getValidationSchema = (isGB: boolean) =>
  isGB
    ? Yup.object().shape({
        ...common,
        duties: Yup.string().required(translate(translations.blank)),
        leavingReason: Yup.string().when('isOngoing', {
          is: isOngoing => isOngoing === false,
          then: schema => schema.required(translate(translations.blank)),
        }),
      })
    : Yup.object().shape({
        ...common,
      })

export default getValidationSchema
