import * as Yup from 'yup'

import { regexes } from 'src/global/utils'
import translations, { translate } from 'src/utils/translations/translations'

export const validationSchemaCompletedUser = Yup.object().shape({
  bankAccountHolderName: Yup.string().nullable().required(translate(translations.blank)),
  bankAccountNumber: Yup.string()
    .nullable()
    .required(translate(translations.blank))
    .test({
      name: 'bankAccountNumber',
      test: function test(value) {
        return !!value && value.length === 8 && regexes.numbersWithZero.test(value)
      },
      message: () => translate(translations.bankAccountNumberError),
    }),
  nationalInsuranceNumber: Yup.string().nullable().required(translate(translations.blank)),
  bankName: Yup.string().nullable().required(translate(translations.blank)),
  bankSortCode: Yup.string()
    .nullable()
    .required(translate(translations.blank))
    .test({
      name: 'bankSortCode',
      test: function test(value) {
        return !!value && value.length === 6 && regexes.numbersWithZero.test(value)
      },
      message: () => translate(translations.bankSortCodeError),
    }),
})

const validationSchema = validationSchemaCompletedUser.shape({
  invoicingConfig: Yup.object({
    ukTaxCode: Yup.string().nullable().required(translate(translations.blankOption)),
    postgraduateLoanFullyRepaid: Yup.bool().nullable(),
    postgraduateLoanLeftBeforeApril: Yup.bool()
      .nullable()
      .when('postgraduateLoanFullyRepaid', {
        is: true,
        then: schema => schema.nullable().required(translate(translations.blankOption)),
      }),
    studentLoanFullyRepaid: Yup.bool().nullable(),
    studentLoanLeftBeforeApril: Yup.bool()
      .nullable()
      .when('studentLoanFullyRepaid', {
        is: true,
        then: schema => schema.nullable().required(translate(translations.blankOption)),
      }),
  }),
  loanPlanType: Yup.string()
    .nullable()
    .when('invoicingConfig.studentLoanFullyRepaid', {
      is: true,
      then: schema => schema.required(translate(translations.blankOption)),
    }),
})

export default validationSchema
